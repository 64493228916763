@import url('bootstrap/dist/css/bootstrap.min.css');

.App {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.students-list {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
}

.student-item {
  display: flex !important;
  gap: 1rem;
  align-items: start;
  justify-content: space-between;
  padding: 1rem;
}

.student-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
}


.student-action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.celebration-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Asegúrate de que esté por encima de otros elementos */
}

.celebration-container img {
    width: 60dvw;
}
